.photo {
  width: 300px;
  height: 300px;
  margin: 15px;
  transition: 0.3s ease-in-out;
  border-radius: 15px;
}
.photo:hover {
  transform: scale(1.1);
}
.positionphoto {
  text-align: center;
  margin: 0 auto;
}

.opis {
  font-size: 25px;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 60px;
  font-family: "Permanent Marker", sans-serif;
}

@media (min-width: 768px) {
  .opis {
    font-size: 36px;
  }
}
