.container {
  max-width: 375px;
  margin: 0 auto;
  padding: 0 20px 50px 20px;
  @media (min-width: 768px) {
    max-width: 1200px;
  }
}

.bg {
  position: relative;
}

.bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../../assets/alpfoto/realiza/panorama.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.2;
  z-index: -1;
}
.flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.title {
  text-align: center;
  font-size: 20px;
}
.desc {
  text-align: center;
  padding-top: 60px;
  margin: 0 auto 80px auto;
  width: 80%;
  text-align: center;
  font-size: 20px;
  line-height: 1.4;
  font-family: "Roboto", sans-serif;
}
.cyfra {
  color: beige;
  font-size: 40px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
}
.box {
  margin: 0 auto;
  width: 80%;
  height: 200px;
  border: 2px solid beige;
  border-radius: 20px;
  margin-bottom: 20px;
  padding: 10px;
}
.box:hover {
  transform: scale(1.1);
  background-color: #9b6b6b;
  box-shadow: 0 4px 40px rgba(255, 215, 0, 0.5);
}
.jak {
  text-align: center;
  font-size: 40px;
  padding-top: 60px;
  padding-bottom: 40px;
}
.opis {
  text-align: center;
}
@media (min-width: 768px) {
  .flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
  .title {
    margin: 0 auto;
    font-size: 20px;
  }
  .desc {
    padding-top: 100px;
    margin: 0 auto 80px auto;
    width: 80%;
    text-align: center;
    font-size: 25px;
    line-height: 1.4;
    font-weight: 700;
  }
  .cyfra {
    color: white;
    font-size: 40px;
    margin: 0 auto 20px auto;
  }
  .opis {
    color: beige;
    font-size: 19px;
  }
  .box {
    margin: 20px;
    padding: 15px;
    width: 200px;
    height: auto;
    border: 2px solid white;
    border-radius: 20px;
    transition: 0.3s ease-in-out;
  }

  .box:hover {
    transform: scale(1.1);
    background-color: #9b6b6b;
    box-shadow: 0 4px 40px rgba(255, 215, 0, 0.5);
  }

  .bg {
    position: relative;
  }

  .bg::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../../assets/alpfoto/realiza/panorama.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.4;
    z-index: -1;
  }
  .jak {
    text-align: center;
    font-size: 40px;
    padding-top: 60px;
    padding-bottom: 40px;
  }
}
