.container {
  max-width: 375px;
  margin: 0 auto;
  padding: 0 20px 50px 20px;
  @media (min-width: 768px) {
    max-width: 1200px;
  }
}

.bg {
  position: relative;
}

.bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../../../assets/alpfoto/realiza/16.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.4;
  z-index: -1;
}

.title {
  text-align: center;
  font-size: 25px;
  padding-top: 40px;
  padding-bottom: 40px;
  margin-top: 50px;
  text-transform: uppercase;
  font-family: "Permanent Marker", sans-serif;
}

.desc {
  text-align: center;
  width: 100%;
  margin: 0 auto;
  font-size: 16px;
  line-height: 1.15;
  font-weight: 500;
  padding-bottom: 100px;
  color: beige;
  font-family: "Roboto", sans-serif;
  line-height: 1.4em;
}

@media (min-width: 768px) {
  .title {
    font-size: 36px;
    padding-bottom: 100px;
    padding-top: 100px;
  }
  .desc {
    font-size: 25px;
    padding-bottom: 200px;
  }
}
