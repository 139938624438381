.bg {
  width: 100%;

  margin-top: 80px;
  display: block;
  height: 100%;
  background-image: url(../../assets/alpfoto/realiza/panorama4.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.logo {
  margin: 40px;
  width: 100px;
  height: 100px;
}

.icon {
  margin: 10px 20px 0 10px;
  transition: transform 0.3s ease-in-out;
}
.width {
  margin: 0 auto 0 auto;

  width: 90%;
  display: flex;
  flex-direction: column;
}

.content {
  // margin-left: 60px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.link {
  color: white;
  text-decoration: none;
  font-family: "Rubik", sans-serif;
  // margin-top: 15px;
  margin-right: 40px;
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.odwiedz {
  margin-top: 40px;
}
.flexik {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // justify-content: space-between;
  // margin: 0 auto;
}
.flexkolumn {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
.title {
  text-align: center;
  margin-top: 20px;
  font-size: 40px;
  margin-bottom: 10px;
  color: black;
  font-family: "Permanent Marker", sans-serif;
}
.phrase {
  color: black;

  text-align: center;
  font-size: 30px;
  font-weight: bold;
  margin: 10px auto 0 auto;
  font-family: "Roboto", sans-serif;
  line-height: 1.4em;
}

.phrase2 {
  color: yellow;

  text-align: center;
  font-size: 30px;
  font-weight: bold;
  margin: 50px auto 30px auto;
}
.style {
  text-decoration: none;
  color: yellow;
  font-family: "Roboto", sans-serif;
  line-height: 1.4em;
  font-size: 20px;
  font-weight: 900;
}
.tel {
  display: block;
  width: 80px;
  height: 60px;

  margin: 20px auto 15px auto;
}

.email {
  display: block;
  margin: 20px auto 15px auto;

  width: 70px;
  height: 60px;
}

.posbutton {
  margin: 40px auto 90px auto;
  width: 200px;
  height: 50px;
  border-radius: 20px;
  background-color: yellow;
  outline: none;
  cursor: pointer;
  color: black;
  font-family: "Roboto", sans-serif;
  line-height: 1.4em;
  font-size: 16px;
  font-weight: 600;
}
.pos {
  margin: 0 auto;
}
@media (min-width: 768px) {
  .title {
    text-align: center;
    margin-top: 20px;
    font-size: 40px;
    margin-bottom: 50px;
    color: black;
  }
  .phrase {
    color: rgb(255, 255, 255);

    text-align: center;
    font-size: 30px;
    font-weight: bold;
    margin: 30px auto 50px auto;
  }
  .phrase2 {
    color: beige;

    text-align: center;
    font-size: 30px;
    font-weight: bold;
    margin: 20px auto 30px auto;
  }

  .bg {
    width: 100%;
    margin-top: 80px;
    display: block;
    height: 450px;
    background-image: url(../../assets/alpfoto/realiza/panorama4.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .logo {
    margin: 40px;
    width: 100px;
    height: 100px;
  }

  .icon {
    margin: 10px 20px 0 10px;
    transition: transform 0.3s ease-in-out;
  }
  .width {
    margin: 0 auto 0 auto;

    max-width: 1200px;
    display: flex;
    flex-direction: column;
  }

  .content {
    // margin-left: 60px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .link {
    color: black;
    text-decoration: none;
    font-family: "Rubik", sans-serif;
    // margin-top: 15px;
    margin-right: 40px;
    margin-bottom: 15px;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  .odwiedz {
    margin-top: 40px;
  }
  .flexik {
    // margin-left: 50px;
    // margin-right: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .flexkolumn {
    display: flex;
    flex-direction: column;
  }
  .posbutton {
    margin: 30px auto 0 auto;
    width: 200px;
    height: 50px;
    border-radius: 20px;
    background-color: yellow;
    outline: none;
    cursor: pointer;
    color: black;
  }
}
