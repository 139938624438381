.flex {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin: 0 auto;
  max-width: 375px;
  margin: 0 auto;
  padding: 0 20px 50px 20px;
}
.dzial {
  margin-bottom: 40px;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
}
.opis {
  color: beige;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  line-height: 1.4em;
}
.img {
  width: 50px;
  height: 50px;
  padding: 15px;
}

.margin {
  text-align: center;
  padding-top: 80px;
}
.box {
  border: 1px solid white;
  border-radius: 20px;
  // width: 300px;
  height: auto;
  padding: 20px;
  margin: 0 auto 20px auto;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.box:hover {
  transform: scale(1.1);
  background-color: #9b6b6b;
  box-shadow: 0 4px 40px rgba(255, 215, 0, 0.5);
  // box-shadow: 15px 15px 30px rgba(0, 0, 0, 0.3);
}
.link {
  text-decoration: none;
  color: white;
}
.title {
  padding-bottom: 60px;
  font-family: "Permanent Marker", sans-serif;
}
@media (min-width: 768px) {
  .flex {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
    justify-content: center;
    max-width: 1200px;
  }
  .img {
    width: 100px;
    height: 100px;
    padding: 15px;
  }

  .margin {
    text-align: center;
  }
  .box {
    border: 1px solid white;
    border-radius: 20px;
    width: 80%;
    height: 350px;
    padding: 20px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }
  .box:hover {
    transform: scale(1.1);
    background-color: black;
    opacity: 0.6;
    box-shadow: 0 4px 40px rgba(255, 215, 0, 0.5);
    // box-shadow: 15px 15px 30px rgba(0, 0, 0, 0.3);
  }
  .link {
    text-decoration: none;
    color: white;
  }
  .title {
    padding-bottom: 60px;
    font-size: 40px;
  }
}
