// .container {
//   max-width: 375px;
//   margin: 0 auto;
//   padding: 0 20px 0 20px;
//   @media (min-width: 768px) {
//     max-width: 1200px;
//   }
// }

body {
  margin: 0;
  background-color: black;
  color: white;
}
