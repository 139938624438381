.nav {
  font-size: 40px;
}

.navdesktop {
  display: none;
}
.display {
  margin-top: 30px;
  width: 100%;
  height: 200px;
  // background-image: url(../../assets/alpfoto/mainpage.png);
  background-image: url(../../assets/alpfoto/realiza/bicek.jpg);

  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: space-between;
}
.features {
  text-align: center;
  padding: 10px;
  width: auto;
  height: 20px;
}
.btn {
  background: none;
  border: none;
  margin-bottom: 120px;
  margin-right: 20px;
  cursor: pointer;
  z-index: 9;
}
.logo {
  width: 80px;
  height: 80px;
  margin-top: 15px;
  margin-left: 15px;
  border-radius: 20px;
}

@media (min-width: 768px) {
  .logo {
    margin-top: 60px;
    width: 100px;
    height: 100px;
    border-radius: 20px;
  }
  .nav {
    display: none;
  }
  .display {
    display: none;
  }
  .boxnav {
    background-color: grey;
    // opacity: 0.8;
    width: 80%;
    height: 60px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
    // border: 2px solid black;
    margin-top: 70px;
    display: flex;
    justify-content: space-between;
  }
  .bgim {
    background-image: url(../../assets/alpfoto/realiza/bicek.jpg);
    background-repeat: no-repeat;
    background-size: cover;
  }
  .navdesktop {
    width: 100%;
    max-width: 1280px;
    height: 100vh;
    // margin-top: 50px;
    margin: 0 auto;
    // margin-bottom: 100px;
    // margin-right: 100px;
    display: flex;
    justify-content: space-between;
  }
  .features {
    text-decoration: none;
    // margin-left: 190px;
    // margin-top: 20px;
    text-align: center;
    padding: 10px;
    width: auto;
    height: 20px;
    font-size: 20px;
    // color: hsl(229, 8%, 60%);
    color: black;
    // color: yellow;
    opacity: 1;

    letter-spacing: 1.25px;
    text-transform: uppercase;
    font-family: "Permanent Marker", sans-serif;
    transition: transform 0.8s ease-in-out;
    cursor: pointer;
  }
  .features:hover {
    transform: scale(1.2);
    transform-origin: center;
  }
  .pricing {
    text-decoration: none;
    // margin-right: 30px;
    // margin-top: 20px;

    text-align: center;
    padding: 10px;
    width: auto;
    height: 20px;
    font-size: 20px;
    // color: hsl(229, 8%, 60%);
    color: black;

    letter-spacing: 1.25px;
    text-transform: uppercase;
    font-family: "Permanent Marker", sans-serif;
    transition: transform 0.8s ease-in-out;
    cursor: pointer;
  }
  .pricing:hover {
    transform: scale(1.2);
    transform-origin: center;
  }
  .oferta {
    text-decoration: none;
    // margin-right: 30px;
    // margin-top: 20px;

    font-size: 20px;
    text-align: center;
    padding: 10px;
    width: auto;
    height: 20px;
    // color: hsl(229, 8%, 60%);
    color: black;

    letter-spacing: 1.25px;
    text-transform: uppercase;
    font-family: "Permanent Marker", sans-serif;
    transition: transform 0.8s ease-in-out;
    cursor: pointer;
  }
  .oferta:hover {
    transform: scale(1.2);
    transform-origin: center;
  }

  .contact {
    text-decoration: none;
    // margin-right: 30px;
    // margin-top: 20px;
    text-align: center;
    padding: 10px;
    width: auto;
    height: 20px;
    font-size: 20px;
    // color: hsl(229, 8%, 60%);
    color: black;

    letter-spacing: 1.25px;
    text-transform: uppercase;
    font-family: "Permanent Marker", sans-serif;
    transition: transform 0.8s ease-in-out;
    cursor: pointer;
  }
  .contact:hover {
    transform: scale(1.2);
    transform-origin: center;
  }
  .btn {
    cursor: pointer;
    // cursor: not-allowed;

    margin-left: 20px;
    margin-right: 30px;
    width: 150px;
    outline: none;
    height: 50px;
    border-radius: 8px;
    color: beige;
    background-color: #512eb3;
    z-index: 9;
    // text-transform: uppercase;
    font-family: "Arial Black", sans-serif;
    transition: transform 0.8s ease-in-out;
    opacity: 1;
  }
  .btn:hover {
    transform: scale(1.2);
    transform-origin: center;
  }
}
