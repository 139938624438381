.bgmodal {
  // background-color: white;
  background-image: url(../../assets//alpfoto/realiza/panorama.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 300px;
  height: 500px;
  position: fixed;
  top: 200px;
  left: 40px;
  border-radius: 15px;
}
.close {
  color: black;
  font-size: 30px;
  text-align: end;
  margin-right: 30px;
  cursor: pointer;
}
.title {
  color: beige;
  font-size: 20px;
  margin-bottom: 5px;
  margin-top: 10px;
}
.flex {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.input::placeholder {
  width: 200px;
  height: 100px;
  padding: 10px;
  color: black;
}

.input {
  width: 100%;
  height: 20px;
  border: 2px solid black;
  border-radius: 8px;
  opacity: 0.5;
  padding: 10px;
}
.input2 {
  width: 100%;
  height: 100px;
  border: 2px solid black;
  border-radius: 8px;
  opacity: 0.5;
  padding: 10px;
}
.input2::placeholder {
  width: 200px;
  height: 100px;
  padding: 10px;
  color: black;
}
.btn {
  margin: 30px auto 0 auto;
  width: 80%;
  background-color: yellow;
  font-size: 16px;
  border-radius: 8px;
  padding: 8px;
  color: black;
  cursor: pointer;
}
@media (min-width: 768px) {
  .bgmodal {
    width: 500px;
    height: 600px;
    position: fixed;
    top: 50px;
    left: 30%;
    z-index: 9;
  }
}
