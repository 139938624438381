.container {
  max-width: 375px;
  margin: 0 auto;
  padding: 0 20px 50px 20px;
  @media (min-width: 768px) {
    max-width: 1200px;
  }
}

.bg {
  position: relative;
}

.bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../../../assets/alpfoto/realiza/17.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.4;
  z-index: -1;
}

.title {
  text-align: center;
  font-size: 25px;
  padding-top: 50px;
  // padding-bottom: 50px;
  margin: 0 auto 30px auto;
  text-transform: uppercase;
  font-family: "Permanent Marker", sans-serif;
}

.title2 {
  text-align: center;
  font-size: 25px;
  //   padding-top: 50px;
  padding-bottom: 50px;
  //   margin-top: 50px;
  text-transform: uppercase;
}
.desc {
  text-align: center;
  width: 90%;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 500;
  // padding-bottom: 50px;
  color: beige;
  font-family: "Roboto", sans-serif;
  line-height: 1.4em;
}
.li {
  text-align: center;
  width: 100%;
  font-size: 30px;
  font-weight: 500;
  color: beige;
  padding-bottom: 10px;
}

@media (min-width: 768px) {
  .bg {
    position: relative;
  }

  .bg::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../../../assets/alpfoto/realiza/17.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.4;
    z-index: -1;
  }

  .title {
    text-align: center;
    font-size: 30px;
    padding-top: 100px;
    padding-bottom: 50px;
    margin-top: 50px;
    text-transform: uppercase;
  }

  .title2 {
    text-align: center;
    font-size: 30px;
    //   padding-top: 50px;
    padding-bottom: 50px;
    //   margin-top: 50px;
    text-transform: uppercase;
  }
  .desc {
    text-align: center;
    width: 100%;
    margin: 0 auto;
    font-size: 20px;
    line-height: 1.15;
    font-weight: 500;
    padding-bottom: 50px;
    color: beige;
  }
  .li {
    font-size: 30px;
    font-weight: 500;
    color: beige;
    padding-bottom: 10px;
  }
}
