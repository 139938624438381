.bg {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  width: 100%;
  max-width: 100%;
  height: 100%;
}
.firstlane {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.logo {
  width: 150px;
  height: 150px;
  margin: 20px;
}
.close {
  width: 20px;
  height: 20px;
  margin-top: 40px;
  margin-right: 20px;

  cursor: pointer;
}
.features {
  display: block;
  text-decoration: none;
  padding: 15px;
  margin: 0 auto;
  width: 80%;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  font-size: 20px;
  text-align: center;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  letter-spacing: 1.75px;
  font-family: "Rubik", sans-serif;
}
.pricing {
  display: block;
  text-decoration: none;
  cursor: pointer;
  letter-spacing: 1.75px;
  font-family: "Rubik", sans-serif;
  padding: 15px;

  margin: 0 auto;
  width: 80%;
  font-size: 20px;
  text-align: center;
  color: white;
  text-transform: uppercase;
  border-bottom: 1px solid grey;
}
.contact {
  letter-spacing: 1.75px;
  font-family: "Rubik", sans-serif;
  display: block;
  text-decoration: none;
  cursor: pointer;

  padding: 15px;

  margin: 0 auto;
  width: 80%;
  font-size: 20px;
  text-align: center;
  color: white;
  text-transform: uppercase;
  border-bottom: 1px solid grey;
}
.login {
  letter-spacing: 1.75px;
  font-family: "Rubik", sans-serif;
  display: block;
  margin: 30px auto 0 auto;
  width: 60%;
  height: 40px;
  background-color: hsla(229, 31%, 21%, 0.9);
  outline: none;
  border: 2px solid white;
  border-radius: 10px;
  color: white;
  font-size: 18px;
  text-transform: uppercase;
}
.positionsocial {
  display: flex;
  justify-content: center;
}
.icon {
  margin: 30px 20px 0 20px;
}

@media (min-width: 768px) {
  .bg {
    display: none;
  }
}
